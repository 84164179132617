// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-products-item-js": () => import("/opt/build/repo/src/templates/products-item.js" /* webpackChunkName: "component---src-templates-products-item-js" */),
  "component---src-templates-legal-js": () => import("/opt/build/repo/src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-customer-testimonials-js": () => import("/opt/build/repo/src/templates/customer-testimonials.js" /* webpackChunkName: "component---src-templates-customer-testimonials-js" */),
  "component---src-templates-blog-tag-js": () => import("/opt/build/repo/src/templates/blog-tag.js" /* webpackChunkName: "component---src-templates-blog-tag-js" */),
  "component---src-templates-blog-author-js": () => import("/opt/build/repo/src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-awsmarketplace-js": () => import("/opt/build/repo/src/pages/awsmarketplace.js" /* webpackChunkName: "component---src-pages-awsmarketplace-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("/opt/build/repo/src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-casestudies-js": () => import("/opt/build/repo/src/pages/casestudies.js" /* webpackChunkName: "component---src-pages-casestudies-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customers-js": () => import("/opt/build/repo/src/pages/customers.js" /* webpackChunkName: "component---src-pages-customers-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partners-js": () => import("/opt/build/repo/src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-platform-storyfier-js": () => import("/opt/build/repo/src/pages/platform/storyfier.js" /* webpackChunkName: "component---src-pages-platform-storyfier-js" */),
  "component---src-pages-products-js": () => import("/opt/build/repo/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-warden-js": () => import("/opt/build/repo/src/pages/products/warden.js" /* webpackChunkName: "component---src-pages-products-warden-js" */),
  "component---src-pages-reports-js": () => import("/opt/build/repo/src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-solutions-js": () => import("/opt/build/repo/src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-solutions-compliance-js": () => import("/opt/build/repo/src/pages/solutions/compliance.js" /* webpackChunkName: "component---src-pages-solutions-compliance-js" */),
  "component---src-pages-solutions-cyber-hygiene-js": () => import("/opt/build/repo/src/pages/solutions/cyber-hygiene.js" /* webpackChunkName: "component---src-pages-solutions-cyber-hygiene-js" */),
  "component---src-pages-solutions-data-privacy-js": () => import("/opt/build/repo/src/pages/solutions/data-privacy.js" /* webpackChunkName: "component---src-pages-solutions-data-privacy-js" */),
  "component---src-pages-solutions-penetration-testing-js": () => import("/opt/build/repo/src/pages/solutions/penetration-testing.js" /* webpackChunkName: "component---src-pages-solutions-penetration-testing-js" */),
  "component---src-pages-solutions-security-aws-js": () => import("/opt/build/repo/src/pages/solutions/security-aws.js" /* webpackChunkName: "component---src-pages-solutions-security-aws-js" */),
  "component---src-pages-solutions-vciso-js": () => import("/opt/build/repo/src/pages/solutions/vciso.js" /* webpackChunkName: "component---src-pages-solutions-vciso-js" */)
}

